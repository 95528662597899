import React, { useState } from 'react';
import TabContext from '@mui/lab/TabContext';
import { MapRuleCss } from '../ManageRuleCss';
import { PuffLoader } from 'react-spinners';
import CloseIcon from '@material-ui/icons/Close';
import { ARMLogo } from '../../../../../constants/Constant';
import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TooltipSmall from '../../../../GlobalStyles/TooltipSmall';
import { useFetch, usePost } from '../../../../../utils/apiHelper';
import { Transition } from '../../../../GlobalStyles/DialogBoxTransition';
import { useStyles } from '../../../../../pages/NotificationCenter/NotificationCenterCss';
import { APIPlacementControlRule } from '../../../../../models/APIPlacementRule/DynamicControlRule'
import { IGetPlacementDefaultRule, IPlacementRuleCategory, ICheckedFields, RuleOperationTypeMap, IGetValidFieldResult, IStateDetails } from '../../../../../models/accesscontrol/PlacementTemplateRule';
import {
    AppBar, Autocomplete, Backdrop, Box, Button, Checkbox, CircularProgress, Container, Dialog, DialogContent, FormControl, FormControlLabel, Grid, IconButton, InputLabel,
    Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography
} from '@mui/material';
import { IClientCodeDetails } from '../../../../../models/GLobalSettings/IGetKeyValueDetails';
import { IClientPhase } from '../../../../../models/Placements/IClientPhase';

const MapPlacementRules = (props) => {
    const classes = useStyles();
    const classes1 = MapRuleCss();
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false);
    const [results, setResults] = useState<IGetValidFieldResult[]>([]);
    const [loading, setLoading] = useState(false);
    const { ruleFieldName, entityId, apiKeyId, isButtonDisabled } = props;
    const [fieldValue, setFieldValue] = useState('');
    const [selectedTab, setSelectedTab] = useState<number>(1);
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [ruleValues, setRuleValues] = useState<RuleOperationTypeMap>({});
    const [defaultRules, setDefaultRules] = useState<IGetPlacementDefaultRule[]>([]);
    const [checkedRules, setCheckedRules] = useState<ICheckedFields>({});
    const [isDefaultChecked, setIsDefaultChecked] = useState<ICheckedFields>({});
    const [rulesSaved, setRulesSaved] = useState<boolean>(false);
    const [placementRuleGategory, setPlacementRuleGategory] = useState<IPlacementRuleCategory[]>([]);
    const [clientCodeList, setClientCodeList] = useState<IClientCodeDetails[]>([]);
    const [clientPhase, setClientPhase] = useState<IClientPhase[]>([]);
    const [stateList, setStateList] = useState<IStateDetails[]>([]);
    const [selectedPhase, setSelectedPhase] = useState<number>(-1);
    const [clientCode, setClientCode] = useState('');
    const [state, setState] = useState('');
    const [showPhase, setShowPhase] = useState(true);

    const handleOpen = () => {
        setOpen(true);
        getPlacementRuleCategory();
        getPlacementDefaultRule();
        getFieldDataRules();
        handleClientDetail(apiKeyId);
        handleStateDetail();
    };

    const handleClose = () => {
        setOpen(false);
        setFieldValue('');
        setClientCode('');
        setState('');
        setResults([]);
        setSelectedTab(1)
    };

    const handleCheckboxChange = (ruleOperationTypeEnumId: number) => (event) => {
        setCheckedRules(prevState => ({
            ...prevState,
            [ruleOperationTypeEnumId]: event.target.checked,
        }));
    };

    const handleDefaultCheckboxChange = (ruleOperationTypeEnumId: number) => (event) => {
        const isChecked = event.target.checked;

        setIsDefaultChecked(prevState => ({
            ...prevState,
            [ruleOperationTypeEnumId]: isChecked,
        }));

        setRuleValues(prevState => {
            const defaultRule = defaultRules.find(rule => rule.ruleOperationTypeEnumId === ruleOperationTypeEnumId)?.ruleValue;
            return {
                ...prevState,
                [ruleOperationTypeEnumId]: isChecked ? defaultRule : null,
            };
        });
    };

    const handleSubmit = () => {
        setLoading(true);
        const numbersArray = fieldValue.split(',').map(num => num.trim()).filter(num => num.length > 0);
        (async () => {
            let clientHeaderValue = {
                clientCode: clientCode,
                phase: selectedPhase === 1 ? "1st Party" : selectedPhase === 2 ? "Flat Fee" : selectedPhase === 3 ? "Contingency" : null,
                state: state
            };
            let request = {
                entityId: entityId,
                fieldId: ruleFieldName?.fieldId,
                value: numbersArray,
                clientHeader: clientHeaderValue
            };
            await usePost<IGetValidFieldResult[]>('ARMPlacementAccountTest/ValidateArmPlacementAccount', request).then((response) => {
                setResults(response.data);
            }).catch((error) => {
                console.error('Error during API call:', error);
            }).finally(() => {
                setLoading(false);
            })
        })()
    }

    const handleValueChange = (event, ruleId: number) => {
        setRuleValues(prevState => ({
            ...prevState,
            [ruleId]: event.target.value,
        }));
    };

    const getPlacementDefaultRule = () => {
        (async () => {
            await useFetch<IGetPlacementDefaultRule[]>("GlobalSettings/GetDefaultFieldRules?FieldId=" + ruleFieldName?.fieldId).then((result) => {
                setDefaultRules(result?.data);
            })
        })()
    }

    const getPlacementRuleCategory = () => {
        setShowProgressBar(true);
        (async () => {
            await useFetch<IPlacementRuleCategory[]>("GlobalSettings/GetPlacementRuleGategory").then((result) => {
                setPlacementRuleGategory(result?.data);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })();
    };

    const handleSubmitDataFieldRules = () => {
        setShowProgressBar(true);
        (async () => {
            const fieldDataRuleDetail = defaultRules?.map(rule => ({
                fieldRuleId: 0,
                ruleCategoryId: rule?.ruleCategoryId,
                ruleOperationTypeEnumId: rule?.ruleOperationTypeEnumId,
                ruleExist: checkedRules[rule?.ruleOperationTypeEnumId] ? true : false,
                ruleCondition: rule?.ruleCondition,
                ruleValue: ruleValues[rule?.ruleOperationTypeEnumId],
                ruleOutput: '',
                ruleIsActive: true
            }));

            let request = {
                entityId: entityId,
                fieldId: ruleFieldName?.fieldId,
                fieldDataRuleDetail
            }
            await usePost("GlobalSettings/ManageFieldDataRules", request).then(() => {
                setShowProgressBar(false);
                setRulesSaved(true);
            })
        })()
    }

    const handleTabChange = (tabId: number) => {
        if (tabId !== selectedTab) {
            setSelectedTab(tabId);
        }
    };

    const getFieldDataRules = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                aPIKeyId: apiKeyId,
                entityId: entityId,
                fieldId: ruleFieldName?.fieldId
            }
            await usePost<IGetPlacementDefaultRule[]>("GlobalSettings/GetPlacementFieldRules", request).then((result) => {
                const extractedRuleValues: RuleOperationTypeMap = {};
                const extractedCheckedRules: ICheckedFields = {};
                result.data.forEach(rule => {
                    extractedRuleValues[rule?.ruleOperationTypeEnumId] = rule?.ruleValue;
                    extractedCheckedRules[rule?.ruleOperationTypeEnumId] = rule?.ruleExist;
                });
                setRuleValues(extractedRuleValues);
                setCheckedRules(extractedCheckedRules);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    }

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setRulesSaved(false);
    };

    const handlePhaseChange = (event) => {
        setSelectedPhase(parseInt(event.target.value));
    };

    const handleClientChange = (e: any, value: IClientCodeDetails) => {
        if (value) {
            setClientCode(value?.clientCode);
            setSelectedPhase(-1);
            (async () => {
                await useFetch<{ placementPhaseResults: IClientPhase[], templateId: number }>(`Placements/GetPlacementPhase?clt_code=${value?.clientCode}`).then((clientPhase) => {
                    setClientPhase(clientPhase?.data?.['placementPhaseResults']);
                });
            })();
            setShowPhase(false);
        }
        else {
            setClientCode('');
            setShowPhase(true);
        }
    };

    const handleStateChange = (e, value) => {
        setState(value?.state);
    }

    // Future Enhancement
    // const handleLoadmockData = () => {
    //     setClientCode(`${clientCodeList[0].clientCode} - ${clientCodeList[0].clientName}`);
    //     setSelectedPhase(3);
    //     setState('CA');
    // }

    const handleClientDetail = (id: number) => {
        setShowProgressBar(true);
        (async () => {
            await useFetch<IClientCodeDetails[]>("GlobalSettings/GetClientCodeList?ApiKeyId=" + id).then((r) => {
                setClientCodeList(r?.data);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    }

    const handleStateDetail = () => {
        setShowProgressBar(true);
        (async () => {
            await useFetch<IStateDetails[]>("GlobalSettings/GetStateList").then((result) => {
                setStateList(result?.data);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    }

    const renderSubControl = (row) => {
        const allComponents = [];
        switch (row?.ruleControlName) {
            case APIPlacementControlRule.TextField:
                {
                    allComponents.push(
                        <TextField size="small" variant="outlined" autoComplete="off" fullWidth
                            id={`${row.fieldRuleId}`}
                            label={`${row?.ruleOperationName}`}
                            value={ruleValues[row.ruleOperationTypeEnumId] || ""}
                            onChange={(e) => handleValueChange(e, row.ruleOperationTypeEnumId)}
                        />
                    )
                    break;
                }
            case APIPlacementControlRule.RadioButton:
                {
                    allComponents.push(
                        <FormControl component="fieldset" key={row?.ruleOperationTypeEnumId}>
                            <RadioGroup row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name={`${row?.ruleOperationName}`}
                                value={ruleValues[row.ruleOperationTypeEnumId]}
                                onChange={(e) => handleValueChange(e, row.ruleOperationTypeEnumId)}
                            >
                                {(row?.defaultControlValue?.split("|"))?.map((result, index) => {
                                    return (
                                        <FormControlLabel
                                            key={index}
                                            value={result}
                                            control={<Radio size="small" sx={{ padding: "5px" }} />}
                                            label={`${result === '1' ? 'True' : result === '0' ? 'False' : result}`}
                                        />
                                    );
                                })}
                            </RadioGroup>
                        </FormControl>
                    );
                    break;
                }
            case APIPlacementControlRule.Dropdown:
                {
                    let dropDownValue;
                    let optionItems;
                    allComponents.push(
                        <FormControl size="small" variant="outlined" fullWidth>
                            <InputLabel htmlFor="outlined-age-native-simple"> {row?.ruleOperationName} </InputLabel>
                            <Select native
                                onChange={(e) => handleValueChange(e, row.ruleOperationTypeEnumId)}
                                label={`${row?.ruleOperationName}`}
                                name={`${row?.ruleOperationName}`}
                                id={`${row?.fieldRuleId}`} >
                                {dropDownValue = (row?.defaultControlValue).split("|")}
                                {optionItems = dropDownValue.map((r) => {
                                    return (
                                        <option value={r}>{r}</option>
                                    )
                                })
                                }
                            </Select>
                        </FormControl>
                    )
                    break;
                }
        }
        return allComponents;
    }

    return (
        <React.Fragment>
            <Button variant='contained' size='medium' fullWidth onClick={handleOpen} disabled={isButtonDisabled}>
                Field Rules
            </Button>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} className={classes.dialog}>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar className={classes.toolbarStyle}>
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <img alt="ARM_Solutions" className={classes.menuButton1} src={ARMLogo} />
                        </IconButton>
                        <Typography variant="body1" className={classes.title}>
                            <b>{ruleFieldName?.fieldDisplayName.toUpperCase()} FIELD RULES</b>
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close" style={{ padding: '5px' }}>
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <DialogContent sx={{ mt: 6, padding: 0 }}>
                    <Container maxWidth="xl">
                        <Grid container spacing={0} mt={1}>
                            <Grid item xs={12} >
                                <Box width={'100%'} borderRadius={2} bgcolor={'white'}>
                                    <TabContext value={value}>
                                        <Box display={'flex'} borderBottom={'1px solid #cc'} >
                                            {placementRuleGategory?.map(tab => (
                                                <Button
                                                    key={tab.ruleCategoryId}
                                                    onClick={() => handleTabChange(tab.ruleCategoryId)}
                                                    aria-selected={selectedTab === tab.ruleCategoryId}
                                                    sx={{
                                                        flex: 1,
                                                        m: 1,
                                                        border: selectedTab === tab.ruleCategoryId ? '2px solid #1976d2' : '.5px solid Black',
                                                        backgroundColor: selectedTab === tab.ruleCategoryId ? '#e3f2fd' : 'transparent',
                                                        color: selectedTab === tab.ruleCategoryId ? '#1976d2' : '#000'
                                                    }}
                                                >
                                                    {tab?.rulecategoryName}
                                                </Button>
                                            ))}
                                            <Button
                                                key={placementRuleGategory.length}
                                                aria-selected={selectedTab === placementRuleGategory.length}
                                                onClick={() => handleTabChange(placementRuleGategory.length)}
                                                sx={{
                                                    flex: 1,
                                                    m: 1,
                                                    border: selectedTab === placementRuleGategory.length ? '2px solid #1976d2' : '.5px solid Black',
                                                    backgroundColor: selectedTab === placementRuleGategory.length ? '#e3f2fd' : 'transparent',
                                                    color: selectedTab === placementRuleGategory.length ? '#1976d2' : '#000'
                                                }}
                                            >
                                                Validate and Verify
                                            </Button>
                                        </Box>
                                        <Grid sx={{ '&.MuiBox-root css-19kzrtu': { padding: 0 } }} p={'0px !important'}>
                                            {placementRuleGategory?.filter(tab => tab?.ruleCategoryId === selectedTab)?.map(tab => (
                                                <React.Fragment key={tab.ruleCategoryId}>
                                                    {defaultRules?.filter(rule => rule?.ruleCategoryId === tab?.ruleCategoryId).length > 0 ? (
                                                        <TableContainer className={`scrollbox on-scrollbar ${classes.ruleTable}`} >
                                                            <Table aria-label="dense table" stickyHeader >
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell></TableCell>
                                                                        <TableCell>RULE TYPE</TableCell>
                                                                        <TableCell align="left">OPERATION NAME</TableCell>
                                                                        <TableCell align="center">VALUE</TableCell>
                                                                        <TableCell align="center">DEFAULT VALUE</TableCell>
                                                                        <TableCell align="center">DESCRIPTION</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody >
                                                                    {defaultRules?.filter(rule => rule?.ruleCategoryId === tab?.ruleCategoryId)?.map(row => (
                                                                        <TableRow key={row.fieldRuleId}>
                                                                            <TableCell align="left" className={classes1.TableCellBody}>
                                                                                <Checkbox size='medium' checked={checkedRules[row.ruleOperationTypeEnumId]} onChange={handleCheckboxChange(row?.ruleOperationTypeEnumId)} />
                                                                            </TableCell>
                                                                            <TableCell align="left" className={classes1.TableCellBody}>
                                                                                {tab?.rulecategoryName.toUpperCase()}
                                                                            </TableCell>
                                                                            <TableCell align="left" className={classes1.TableCellBody}>
                                                                                {row?.ruleOperationName.toUpperCase()}
                                                                            </TableCell>
                                                                            <TableCell align="left" className={classes1.TableCellBody}>
                                                                                {renderSubControl(row)}
                                                                            </TableCell>
                                                                            <TableCell align="left" sx={{ display: 'flex' }} className={classes1.TableCellBody}>
                                                                                {row?.isValueRequired && (
                                                                                    <React.Fragment>
                                                                                        <Checkbox size='medium' checked={isDefaultChecked[row.ruleOperationTypeEnumId] || false} onChange={handleDefaultCheckboxChange(row.ruleOperationTypeEnumId)} />
                                                                                        <TextField variant='outlined' size='small' value={row.ruleValue} fullWidth color='primary' label="Default Value" />
                                                                                    </React.Fragment>
                                                                                )}
                                                                            </TableCell>
                                                                            <TableCell align="center" className={classes1.TableCellBody}>
                                                                                <IconButton>
                                                                                    <TooltipSmall title={row.description}>
                                                                                        <InfoOutlinedIcon color='primary' />
                                                                                    </TooltipSmall>
                                                                                </IconButton>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                            <Box m={0} display={'flex'} justifyContent={'flex-end'} sx={{ padding: '16px' }} >
                                                                <Button variant='contained' size='small' sx={{ mt: 1, width: '250px' }} onClick={handleSubmitDataFieldRules}>Submit</Button>
                                                            </Box>
                                                        </TableContainer>
                                                    ) : (
                                                        <Typography variant="h6" gutterBottom className={classes1.noRecordStyle}>
                                                            No records to display...
                                                        </Typography>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                            {selectedTab !== null && selectedTab == placementRuleGategory?.length && (
                                                <TableContainer sx={{
                                                    minHeight: 'calc(100vh - 200px)',
                                                    maxHeight: 'calc(100vh - 200px)',
                                                    border: '1px solid #007FFF',
                                                    borderRadius: '5px',
                                                    padding: 2
                                                }}
                                                    className={`scrollbox on-scrollbar`} key={placementRuleGategory?.length}>
                                                    <Typography variant="h6" gutterBottom align='center'>{ruleFieldName?.fieldDisplayName} Validation</Typography>
                                                    <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', marginTop: 1 }}>
                                                        Form Header
                                                    </Typography>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={4}>
                                                            <FormControl fullWidth size="small" >
                                                                <Autocomplete
                                                                    options={clientCodeList}
                                                                    getOptionLabel={(option) => `${option.clientCode} - ${option.clientName}`}
                                                                    value={clientCodeList.find((option) => option?.clientCode === clientCode)}
                                                                    onChange={handleClientChange}
                                                                    classes={{ paper: classes1.paper1, input: classes1.smallFont1 }}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            placeholder="Select client"
                                                                            variant="outlined"
                                                                            size="small"
                                                                            name="clientCode"
                                                                            className={classes1.dropdownClient}
                                                                            inputProps={{
                                                                                ...params.inputProps,
                                                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                                                                classes: {
                                                                                    root: classes1.outlinedInput1
                                                                                }
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <FormControl id="Select_Phase" variant="outlined" size="small" fullWidth disabled={showPhase} >
                                                                <Select native value={isNaN(selectedPhase) ? -1 : selectedPhase} onChange={handlePhaseChange} className={classes1.dropdownClient}>
                                                                    <option value={-1}>Select Phase</option>
                                                                    {clientPhase?.map((phase) => {
                                                                        return (
                                                                            <>
                                                                                {phase?.istParty ? <option value={1}>1st Party</option> : <option value={-1} className={classes1.NoAccess}>1st Party</option>}
                                                                                {phase?.isFlatFee ? <option value={2}>Flat Fee</option> : <option value={-1} className={classes1.NoAccess}>Flat Fee</option>}
                                                                                {phase?.isContingency ? <option value={3}>Contingency</option> : <option value={-1} className={classes1.NoAccess}>Contingency</option>}
                                                                            </>
                                                                        )
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        {ruleFieldName?.fieldName !== 'BillingState' && ruleFieldName?.fieldName !== 'CustomerState' ?
                                                            <Grid item xs={4}>
                                                                <FormControl fullWidth size="small" >
                                                                    <Autocomplete
                                                                        options={stateList}
                                                                        getOptionLabel={(option) => option?.state}
                                                                        value={stateList.find((option) => option?.state === state)}
                                                                        onChange={handleStateChange}
                                                                        classes={{ paper: classes1.paper1, input: classes1.smallFont1 }}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                placeholder="Select State"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                name="state"
                                                                                className={classes1.dropdownClient}
                                                                                inputProps={{
                                                                                    ...params.inputProps,
                                                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                                                    classes: {
                                                                                        root: classes1.outlinedInput1
                                                                                    }
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            : null
                                                        }
                                                    </Grid>
                                                    <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', marginTop: 1 }}>
                                                        Form Body
                                                    </Typography>
                                                    <TextField
                                                        label={ruleFieldName?.fieldDisplayName}
                                                        multiline rows={3}
                                                        variant="outlined" fullWidth
                                                        value={fieldValue}
                                                        onChange={(e) => setFieldValue(e.target.value)}
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        sx={{ marginBottom: 2 }}
                                                    />
                                                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 2, marginBottom: 2 }}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={handleSubmit}
                                                            disabled={loading || !selectedPhase || !clientCode.length || !state.length || !fieldValue.length}
                                                            size="small"
                                                        >
                                                            {loading ? <CircularProgress size={24} /> : 'Validate'}
                                                        </Button>
                                                        {/* Future Enhancement */}
                                                        {/* <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={handleLoadmockData}
                                                            size="small"
                                                        >
                                                            Load Data
                                                        </Button> */}
                                                    </Box>
                                                    {results.length > 0 && (
                                                        <TableContainer component={Paper}>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell align="left" className={classes1.TableCellStyle}>{ruleFieldName?.fieldDisplayName.toUpperCase()} VALUE</TableCell>
                                                                        <TableCell align="left" className={classes1.TableCellStyle}>VALID</TableCell>
                                                                        <TableCell align="left" className={classes1.TableCellStyle}>ERROR MESSAGE</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {results.map((result, index) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell>{result?.fieldValue}</TableCell>
                                                                            <TableCell >{result?.isValid ? <span className={classes1.isDataValid}>Yes</span> : <span className={classes1.isDataInvalid}>No</span>}</TableCell>
                                                                            <TableCell>{result?.errorMessage || 'N/A'}</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    )}
                                                </TableContainer>)}
                                        </Grid>
                                    </TabContext>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>
            </Dialog>
            <Snackbar
                className="snackBarStyle"
                open={rulesSaved}
                autoHideDuration={4000}
                onClose={closeSnackbar}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Rules Saved Successfully!
                </Alert>
            </Snackbar>
        </React.Fragment >
    );
};

export default MapPlacementRules;
