export const fileMockData = {
    AccountNumber: '123456789',
    ClientCode: 'ARMSOL',
    FirstNameResponsibleParty: 'John',
    LastNameResponsibleParty: 'Doe',
    BillingAddressLine1: '123 Main St',
    BillingAddressLine2: 'Apt 4B',
    BillingCity: 'Springfield',
    BillingState: 'IL',
    BillingZipCode: '62701',
    BillingPhoneNumber: '9995550456',
    FirstDateOfDelinquency: '20230915',
    TotalDue: '265.00',
    ItemizationDate: '20230920',
    BalanceAsOfItemizationDate: '300.00',
    FeesAddSinceItemizationDate: '25.00',
    InterestAddSinceItemizationDate: '5.00',
    PaymentAppliedSinceItemizationDate: '50.00',
    CreditsAppliedSinceItemizationDate: '15.00',
    Phase: 'CONT'
};

export const OPERAND_COUNT = 6;
export const OPERATORS = ['+', '+', '- (', '+', ') ='];