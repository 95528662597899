import {
  AppBar,
  Backdrop, Box, Dialog, IconButton, makeStyles, Slide, TableCell,
  TablePagination, TableRow, TableSortLabel, TextField, Toolbar
} from '@material-ui/core';
import moment from 'moment';
import { CSVLink } from "react-csv";
import { useHistory, useParams } from 'react-router';
import ReactToPrint from "react-to-print";
import { usePost } from '../../utils/apiHelper';
import { green } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import ExpandableRow from './ReportExpandableRow';
import PuffLoader from 'react-spinners/PuffLoader';
import Footer from '../../components/Footer/Footer';
import { IDebtor } from '../../models/Reports/IDebtor';
import LightTooltip from "../GlobalStyles/LightTooltip";
import download from "../../images/files/download01.svg";
import { useGlobalState } from '../../store/GlobalStore';
import React, { useEffect, useRef, useState } from 'react';
import printImg from "../../images/ACKReport/AckPrint.svg";
import { TransitionProps } from '@material-ui/core/transitions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { StyledTableCell, StyledTableRow } from '../GlobalStyles/TableStyledCell';
import { DialogActions, DialogContent, Grid, Paper, Table, TableBody, TableContainer, TableHead, Typography, Button } from "@mui/material";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IReportStatusList } from '../../models/Reports/IReportStatusList';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import cloud from "../../images/Report/download.gif";
import file from "../../images/Report/file.jpg";
import CancelIcon from '@mui/icons-material/Cancel';
import ReplyIcon from '@mui/icons-material/Reply';
import { FixedSizeList as List } from "react-window";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: '8px'
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  tableHeaderStyle: {
    background: "#007FFF",
    color: "white",
    fontSize: "11px",
    padding: '2px 10px'
  },
  tablebody: {
    maxHeight: 640,
    minHeight: 630,
    overflowX: 'hidden',
  },
  fabProgressPrint: {
    color: green[500],
    position: 'absolute',
    top: '-1px',
    left: 13.5,
    zIndex: 1
  },
  fabProgressPrint1: {
    color: green[500],
    position: 'absolute',
    top: '10px',
    right: '83px',
    zIndex: 1,
  },
  wrapper: {
    position: 'relative'
  },
  menuButton: {
    marginRight: theme.spacing(2),
    width: 130,
    marginTop: '5px'
  },
  toolbarStyle: {
    marginTop: '5px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    // borderRadius: '10px',
    backgroundColor: 'white'
  },
  CloseIconColor: {
    color: 'black'
  },
  printTitle: {
    color: "#007fff",
    marginTop: '10px !important',
    fontWeight: 'bold'
  },
  textStyle: {
    color: "blue",
    marginLeft: '5px',
    float: 'left'
  },
  tableBody: {
    maxHeight: 500,
    minHeight: 500,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 'calc( 100vh - 210px)',
      minHeight: 'calc( 100vh - 210px)',
    }
  },
  caption: {
    padding: 8,
    fontSize: 13
  },
  pagination: {
    backgroundColor: '#F5F5F5',
    border: '2px solid #ECECEC'
  },
  tableheaderColor: {
    background: "#007FFF",
    color: "white",
  },
  tableheadercell: {
    fontSize: '11px'
  },
  boxgap: {
    // gap: '8px'
  },
  tablebodyPrint: {
    // maxHeight: 800,
    // overflowX: 'hidden'
  },
  downloadIconStyle: {
    color: "#a1a1a1",
    fontSize: 26,
    marginLeft: '15px'
  },
  downloadIconStyle1: {
    color: "#2377E4",
    fontSize: 26,
    marginLeft: '15px'
  },
  footer: {
    marginTop: '15px',
    float: 'right',
    marginRight: '250px'
  },
  tablegrid: {
    marginTop: '10px'
  },
  norecord: {
    color: "red",
    marginTop: "10px"
  },
  buttonalign: {
    marginTop: '-2px',
    borderRadius: 50
  },
  buttonalign1: {
    borderRadius: 50
  },
  searchBar: {
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    borderRadius: 10,
    border: '2px solid #215176',
    width: '100%',
    fontSize: 14,
    textIndent: '10px',
    marginBottom: '5px',
  },
  GreenColor: {
    color: 'green'
  },
  outlinedInput1: {
    fontSize: 15,
    color: "black",
    marginTop: '-2px',
    padding: '6px 30px 6px 10px !important',
    '&$focused $notchedOutline': {
      color: 'white',
      border: 'none',
    },
  },
  searchTextStyle: {
    padding: '10px !important',
    width: '100%'
  },
  paper1: {
    border: "2px solid black",
  },
  autoCompleteFont: {
    fontSize: 12,
  },
  buttons: {
    borderRadius: 20,
    fontSize: '11px',
    background: "#007FFF",
    color: "white",
    alignContent: 'flex-start',
    justify: "flex-start",
    paddingLeft: 8,
    marginLeft: '30px',
    maxHeight: '30px',
    marginTop: '8px',
  },
  ClearFilterButtonStyle: {
    borderRadius: 20,
    fontSize: '11px',
    background: "#007FFF",
    color: "white",
    marginLeft: '10px',
    paddingLeft: 8,
    marginTop: '8px',
    maxHeight: '30px',
  },
  IconStyle: {
    height: '15px',
    width: '15px',
    marginLeft: '2px'
  },
  SearchDropdown: {
    width: '90%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  placeholder: {
    '&::placeholder': {
      fontSize: '16px',
    },
  },
  dropDownStyle: {
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    borderRadius: 5,
    border: '2px solid #215176',
    backgroundColor: 'white',
    height: '38px',
    fontSize: 12,
    width: '100%'
  },
  CloseButton: {
    borderRadius: '20px !important',
  },
  DialogTitle: {
    backgroundColor: '#007fff',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    height: '50px'
  },
  tableContainer: {
    width: '100%',
    height: 600,
  },
  table: {
    tableLayout: 'fixed',
  },
  tableHead: {
    backgroundColor: theme.palette.grey[200],
  },
  tableCell: {
    padding: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
  rowStyle: {
    display: 'table',
    tableLayout: 'fixed',
    width: '100%',
  },
  appBar: {
    position: 'relative',
    color: '#007fff',
    backgroundColor: 'white',
    minHeight: '35px'
  },
}))

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReportsBuilder(props) {

  let params = useParams();
  const classes = useStyles();
  const prevDBTNo = useRef("");
  const wrapperRef = useRef(null);
  const { state } = useGlobalState();
  const pageStyle = `{ size: 11.7in 16.5in}`;
  const [mapped, setMapped] = useState(false);
  const [order, setOrder] = useState("asc");
  const [isDisable, setIsDisable] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);
  const [openPrint, setopenPrint] = useState(false);
  const [printLoad, setPrintLoad] = useState(false);
  const [orderBy, setOrderBy] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reports, setreports] = useState<IDebtor[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [ProgressBar, setshowProgressBar] = useState(true);
  const [selected, setSelected] = useState<string>();
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(15);
  const [reportStatusId, setReportStatusId] = useState<string>(null);

  const [reportStatusList, setReportStatusList] = useState<IReportStatusList[]>([]);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState<number>(200000);
  const [exportCSV, setExportCSV] = useState<IDebtor[]>([]);
  const [apiCallFinished, setApiCallFinished] = useState(true);
  const [fileSize, setFileSize] = useState('');
  const [recordSize, setRecordSize] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  let history = useHistory();
  const [downloadLoading, setDownloadLoading] = useState(false);

  const handleDownloadClose = () => {
    setOpen(false);
  };

  const handleDialogDownloadClose = () => {
    setDialogOpen(false);
  }

  const handleReportRedirect = () => {
    setOpen(false);
    history.push('/requestedReport');
  };

  const handleClickOpen = () => {
    setopenPrint(true);
  };

  const handleClose = () => {
    setopenPrint(false);
  };

  const handleRowClick = (accountId: string) => {
    setSelected(selected == accountId ? "" : accountId);
    prevDBTNo.current = accountId;
  }

  const handleReportStatusChange = (event, selected) => {
    setPageNumber(0);
    setReportStatusId(selected?.statusCode);
  }

  useEffect(() => {
    (async () => {
      let request = {
        "clients": state.userAccessContext?.clientCodes?.join(","),
        "reportId": params["reportId"],
        "orderBy": orderBy,
        "orderDirection": order,
        "startingRecordNumber": (pageNumber * recordsPerPage) + 1,
        "rowsPerPage": recordsPerPage,
        "userId": state.userAccessContext?.id
      }
      await usePost<IReportStatusList[]>("GetReportStatusList", request).then((result) => {
        setReportStatusList(result?.data);
      }).finally(() => {
      });
    })()
  }, [])

  const handleGetReportStatus = () => {
    (async () => {
      let request = {
        "clients": state.userAccessContext?.clientCodes?.join(","),
        "reportId": params["reportId"],
        "orderBy": orderBy,
        "orderDirection": order,
        "startingRecordNumber": (pageNumber * recordsPerPage) + 1,
        "rowsPerPage": recordsPerPage,
        "userId": state.userAccessContext?.id
      }
      await usePost<IReportStatusList[]>("GetReportStatusList", request).then((result) => {
        setReportStatusList(result?.data);
      }).finally(() => {
      });
    })()
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const formatFileSize = (bytes) => {
    if (bytes < 1024) {
      return bytes.toFixed(2) + ' bytes';
    } else if (bytes < 1048576) {
      return (bytes / 1024).toFixed(2) + ' KB';
    } else if (bytes < 1073741824) {
      return (bytes / 1048576).toFixed(2) + ' MB';
    } else {
      return (bytes / 1073741824).toFixed(2) + ' GB';
    }
  };

  const parseFileSize = (size) => {
    if (!size) return 0;
    const fileSizePattern = /([\d.]+)\s*GB/i;
    const match = size.match(fileSizePattern);
    return match ? parseFloat(match[1]) : 0;
  };

  function getRecordSizeInBytes(record) {
    const jsonString = JSON.stringify(record);
    return new Blob([jsonString]).size;
  }

  useEffect(() => {
    (async () => {
      setshowProgressBar(true);
      let request = {
        "clients": state.userAccessContext?.clientCodes?.join(","),
        "reportId": params["reportId"],
        "orderBy": orderBy,
        "orderDirection": order,
        "startingRecordNumber": (pageNumber * recordsPerPage) + 1,
        "rowsPerPage": recordsPerPage,
        "userId": state.userAccessContext?.id,
        "reportStatus": reportStatusId
      }
      await usePost<{ reports: IDebtor[], totalRecordCount: number }>("ReportDisplay", request).then((GetReportList) => {
        setreports(GetReportList.data["reports"]);
        setTotalRecords(GetReportList.data.totalRecordCount);
        const recordSizes = GetReportList.data["reports"]?.map(r => getRecordSizeInBytes(r));
        const averageRecordSize = recordSizes.reduce((sum, size) => sum + size, 0) / recordSizes.length;
        const totalSizeInBytes = GetReportList.data.totalRecordCount * averageRecordSize;
        const formattedSize = formatFileSize(totalSizeInBytes);
        setFileSize(formattedSize);
        const isFileSize1GBOrAbove = parseFileSize(formattedSize) >= 1;
        setRecordSize(isFileSize1GBOrAbove);
        setMapped(GetReportList.data["reports"].length ? true : false);
        handleGetReportStatus();
      }).finally(() => {
        setIsDisable(true);
        setshowProgressBar(false);
      });
    })()
  }, [pageNumber, recordsPerPage, order, orderBy, reportStatusId])

  const HandleReportDialogOpen = () => {
    setOpen(true);
    (async () => {
      setLoading(true);
      let request = {
        "reportID": parseInt(params["reportId"]),
        "userId": state?.userAccessContext?.id
      }
      await usePost("UserReportRequested", request)
    })().finally(() => {

      setApiCallFinished(false);
      setLoading(false);
    })
  }

  const HandleReportSizeDialogOpen = () => {
    setDialogOpen(true);
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      let request = {
        "clients": state.userAccessContext?.clientCodes?.join(","),
        "reportId": params["reportId"],
        "orderBy": orderBy,
        "orderDirection": order,
        "startingRecordNumber": (pageNumber * recordsPerPage) + 1,
        "rowsPerPage": 200000,
        "userId": state?.userAccessContext?.id,
        "reportStatus": reportStatusId
      }
      await usePost<{ reports: IDebtor[], totalRecordCount: number }>("ReportDisplay", request).then((ExportToCSVFile) => {
        setExportCSV(ExportToCSVFile?.data["reports"]);
      }).finally(() => {
        setLoading(false);
      });
    })()
  }, [order, orderBy, reportStatusId]);

  const handlePageChange = (event, page) => {
    setshowProgressBar(true);
    setPageNumber(page);
  }

  const headers = [
    { label: "Phase", key: "phase" },
    { label: "Client", key: "client" },
    { label: "Agency ID", key: "agencyID" },
    { label: "Clnt Acct 1", key: "clntAcct1" },
    { label: "Client_Name_1", key: "client_Name_1" },
    { label: "Client_Name_2", key: "client_Name_2" },
    { label: "Name", key: "name" },
    { label: "Attention", key: "attention" },
    { label: "Address", key: "address" },
    { label: "Address2", key: "address2" },
    { label: "City", key: "city" },
    { label: "State", key: "state" },
    { label: "Zip", key: "zip" },
    { label: "Last Charge Date", key: "lastChargeDate" },
    { label: "Referral Date", key: "referralDate" },
    { label: "Referral Amt", key: "referralAmt" },
    { label: "Principal Due", key: "principalDue" },
    { label: "Phone Number", key: "phoneNumber" },
    { label: "Fee", key: "fee" },
    { label: "Principal Received", key: "principalReceived" },
    { label: "Status", key: "status" },
    { label: "DEM_ID", key: "deM_ID" },
    { label: "Status Date", key: "statusDate" },
    { label: "DBT_Status", key: "dbT_Status" }
  ];

  function EnhancedTableHead(props) {
    const {
      classes,
      order,
      orderBy,
      onRequestSort
    } = props;
    const createSortHandler = property => event => {
      setshowProgressBar(true);
      onRequestSort(event, property);
    };

    const headCells = [
      { id: "agencyID", disablePadding: false, label: "AGENCY ID", sortable: true },
      { id: "clntAcct1", disablePadding: false, label: "CLNT ACC1", sortable: true },
      { id: "client_Name_1", disablePadding: false, label: "CLIENT NAME1", sortable: true },
      { id: "client_Name_2", disablePadding: false, label: "CLIENT NAME2", sortable: true },
      { id: "name", disablePadding: false, label: "NAME", sortable: true },
      { id: "address", disablePadding: false, label: "ADDRESS", sortable: true },
      { id: "phoneNumber", disablePadding: false, label: "PHONE NUMBER", sortable: true },
      { id: "principalDue", disablePadding: false, label: "PRINCIPAL DUE", sortable: true },
      { id: "fee", disablePadding: false, label: "FEE", sortable: true },
      { id: "principalReceived", disablePadding: false, label: "TOTAL PAID", sortable: true },
      { id: "referralAmt", disablePadding: false, label: "TOTAL DUE", sortable: true },
      { id: "status", disablePadding: false, label: "STATUS", sortable: true },
      { id: "statusDate", disablePadding: false, label: "STATUS DATE", sortable: true },
      { id: "referralDate", disablePadding: false, label: "REFERRAL DATE", sortable: true },
    ];

    return (
      <TableHead>
        <StyledTableRow>
          {headCells.map(headCell => (
            <StyledTableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              className={classes.tableHeaderStyle}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label || headCell.sortable}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
        </StyledTableRow>
      </TableHead>
    );
  }

  const VirtualizedTable: React.FC<{ rows: IDebtor[] }> = ({ rows }) => {
    const Row = React.memo(({ index, style }: { index: number; style: React.CSSProperties }) => {
      const row = rows[index];
      return (
        <div style={{ ...style, display: 'flex', padding: "5px" }} key={row?.agencyID}>
          <div style={{ width: 150 }}><Typography variant='body2' fontSize={"12px"}>{row?.agencyID}</Typography></div>
          <div style={{ width: 150 }}><Typography variant='body2' fontSize={"12px"}>{row?.clntAcct1}</Typography></div>
          <div style={{ width: 200 }}><Typography variant='body2' fontSize={"12px"}>{row?.client_Name_1}</Typography></div>
          <div style={{ width: 200 }}><Typography variant='body2' fontSize={"12px"}>{row?.client_Name_2}</Typography></div>
          <div style={{ width: 150 }}><Typography variant='body2' fontSize={"12px"}>{row?.name}</Typography></div>
          <div style={{ width: 300 }}><Typography variant='body2' fontSize={"12px"}>{row?.address}, {row?.city}, {row?.state}, {row?.zip}</Typography></div>
          <div style={{ width: 150 }}><Typography variant='body2' fontSize={"12px"}>${row?.principalDue.toFixed(2)}</Typography></div>
          <div style={{ width: 150 }}><Typography variant='body2' fontSize={"12px"}>${row?.principalReceived.toFixed(2)}</Typography></div>
          <div style={{ width: 150 }}><Typography variant='body2' fontSize={"12px"}>${row?.referralAmt.toFixed(2)}</Typography></div>
          <div style={{ width: 150 }}><Typography variant='body2' fontSize={"12px"}>{row?.status.replace('|', ' ')}</Typography></div>
          <div style={{ width: 150 }}><Typography variant='body2' fontSize={"12px"}>{row.referralDate ? moment(row?.referralDate).format('MM/DD/YYYY') : null}</Typography></div>
          <div style={{ width: 150 }}><Typography variant='body2' fontSize={"12px"}>{row?.statusDate ? moment(row?.statusDate).format('MM/DD/YYYY') : null}</Typography></div>
        </div>
      );
    });

    const rowHeight = 50;

    return (
      <div>
        <div style={{ display: 'flex', fontWeight: 'bold', backgroundColor: '#007FFF' }}>
          <div style={{ width: 150 }}><Typography variant='body2' fontSize={"12px"} color={'#FFFFFF'} p={"5px 0px 5px 5px"}><b>AGENCY ID</b></Typography></div>
          <div style={{ width: 150 }}><Typography variant='body2' fontSize={"12px"} color={'#FFFFFF'} p={"5px 0px 5px 5px"}><b>CLIENT ACCOUNT</b></Typography></div>
          <div style={{ width: 200 }}><Typography variant='body2' fontSize={"12px"} color={'#FFFFFF'} p={"5px 0px 5px 5px"}><b>CLIENT NAME1</b></Typography></div>
          <div style={{ width: 200 }}><Typography variant='body2' fontSize={"12px"} color={'#FFFFFF'} p={"5px 0px 5px 5px"}><b>CLIENT NAME2</b></Typography></div>
          <div style={{ width: 150 }}><Typography variant='body2' fontSize={"12px"} color={'#FFFFFF'} p={"5px 0px 5px 5px"}><b>NAME</b></Typography></div>
          <div style={{ width: 300 }}><Typography variant='body2' fontSize={"12px"} color={'#FFFFFF'} p={"5px 0px 5px 5px"}><b>ADDRESS</b></Typography></div>
          <div style={{ width: 150 }}><Typography variant='body2' fontSize={"12px"} color={'#FFFFFF'} p={"5px 0px 5px 5px"}><b>PRINCIPAL DUE</b></Typography></div>
          <div style={{ width: 200 }}><Typography variant='body2' fontSize={"12px"} color={'#FFFFFF'} p={"5px 0px 5px 5px"}><b>PRINCIPAL RECEIVED</b></Typography></div>
          <div style={{ width: 150 }}><Typography variant='body2' fontSize={"12px"} color={'#FFFFFF'} p={"5px 0px 5px 5px"}><b>REFERRAL AMOUNT</b></Typography></div>
          <div style={{ width: 150 }}><Typography variant='body2' fontSize={"12px"} color={'#FFFFFF'} p={"5px 0px 5px 5px"}><b>STATUS</b></Typography></div>
          <div style={{ width: 150 }}><Typography variant='body2' fontSize={"12px"} color={'#FFFFFF'} p={"5px 0px 5px 5px"}><b>REFERRAL DATE</b></Typography></div>
          <div style={{ width: 150 }}><Typography variant='body2' fontSize={"12px"} color={'#FFFFFF'} p={"5px 0px 5px 5px"}><b>STATUS DATE</b></Typography></div>
        </div>

        <List
          height={800}
          itemCount={rows.length}
          itemSize={rowHeight}
          width={'100%'}
          itemData={rows}
        >
          {({ index, style }) => <Row index={index} style={style} />}
        </List>
      </div >
    );
  };


  const CHUNK_SIZE = 50000;

  const downloadPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a4",
      compress: true
    });

    const headers = [
      { header: "AGENCY ID", dataKey: "agencyID", width: 160 },
      { header: "CLNT ACC1", dataKey: "clntAcct1", width: 150 },
      { header: "CLIENT NAME1", dataKey: "client_Name_1", width: 200 },
      { header: "CLIENT NAME2", dataKey: "client_Name_2", width: 200 },
      { header: "NAME", dataKey: "name", width: 150 },
      { header: "ADDRESS", dataKey: "address", width: 300 },
      { header: "PHONE NUMBER", dataKey: "phoneNumber", width: 150 },
      { header: "PRINCIPAL DUE", dataKey: "principalDue", width: 200 },
      { header: "FEE", dataKey: "fee", width: 30 },
      { header: "TOTAL PAID", dataKey: "principalReceived", width: 30 },
      { header: "TOTAL DUE", dataKey: "referralAmt", width: 150 },
      { header: "STATUS", dataKey: "status", width: 150 },
      { header: "STATUS DATE", dataKey: "statusDate", width: 150 },
      { header: "REFERRAL DATE", dataKey: "referralDate", width: 150 },
    ];

    const totalRecords = exportCSV.length;
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleDateString();
    };
    const generateChunk = (i) => {
      if (i >= totalRecords) {
        doc.save(`${params["reportName"]}.pdf`);
        setDownloadLoading(false);
        return;
      }

      if (i > 0) doc.addPage();

      const chunk = exportCSV.slice(i, i + CHUNK_SIZE);
      autoTable(doc, {
        head: [headers.map(h => h.header)],
        body: chunk.map(item => headers.map(h => {
          if (h.dataKey === "statusDate" || h.dataKey === "referralDate") {
            return formatDate(item[h.dataKey]);
          }
          return item[h.dataKey];
        })),
        styles: {
          fontSize: 6
        },
        headStyles: {
          fontSize: 8
        },
        columnStyles: headers.reduce((styles, header) => {
          styles[header.dataKey] = { cellWidth: header.width };
          return styles;
        }, {}),
      });
      requestAnimationFrame(() => generateChunk(i + CHUNK_SIZE));
    };
    setDownloadLoading(true);
    requestAnimationFrame(() => generateChunk(0));
  };

  return (
    <div className={classes.root} ref={wrapperRef}>
      <Backdrop className={classes.backdrop} open={ProgressBar}>
        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
      </Backdrop>
      <Grid container spacing={0}>
        <Grid item xs={3}>
          <Typography variant="h6" gutterBottom ml={1} align='left' className={`${"headertitle"}`}>
            <b> {params["reportName"]}</b>
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Autocomplete
            id="combo-box-demo"
            size='small' fullWidth
            className={classes.SearchDropdown}
            classes={{
              paper: classes.paper1,
              option: classes.autoCompleteFont
            }}
            options={reportStatusList?.map(option => ({ statusCode: option.statusCode, statusName: option.statusName }))}
            getOptionLabel={(option) => option.statusName}
            getOptionSelected={(option, value) => option.statusName === value.statusName}
            onChange={(e, selected) => { handleReportStatusChange(e, selected); }}
            renderOption={(option) => (
              <React.Fragment>
                {option?.statusCode} -- {option.statusName}
              </React.Fragment>
            )}
            renderInput={(params) => <TextField {...params} placeholder="Filter by Status"
              className={`${classes.dropDownStyle} ${classes.placeholder}`}
              InputProps={{
                ...params.InputProps,
                classes: {
                  root: classes.outlinedInput1
                }
              }} variant="outlined" />}
          />
        </Grid>
        <Grid item xs={2} display={'flex'} justifyContent={'flex-end'} gap={"0px"}>
          <div className={classes.wrapper}>
            <LightTooltip title="Print Reports List">
              <Button disabled={!exportCSV.length} color="primary" id="RB_PrintIcon" aria-label="print" component="label"
                onClick={() => { handleClickOpen() }} className={classes.buttonalign1}>
                <img src={printImg} alt="Icon for Print" width="25px" height="25px" />
              </Button>
            </LightTooltip>
            {loading && <CircularProgress size={35} className={classes.fabProgressPrint} />}
          </div>

          <div className={classes.wrapper}>
            <LightTooltip title="Download Reports List">
              {recordSize ? (<>
                <IconButton id="RB_CSV" size='small' disabled={!reports.length || !apiCallFinished} className={classes.buttonalign} onClick={HandleReportSizeDialogOpen}>
                  <img src={download} alt="Icon for Download" width="28px" height="28px" className={!exportCSV.length ? classes.downloadIconStyle : classes.downloadIconStyle1} />
                </IconButton>
              </>) : (<>
                {totalRecords >= count ?
                  <IconButton id="RB_CSV" size='small' disabled={!reports.length || !apiCallFinished} className={classes.buttonalign} onClick={HandleReportDialogOpen}>
                    <img src={download} alt="Icon for Download" width="28px" height="28px" className={!exportCSV.length ? classes.downloadIconStyle : classes.downloadIconStyle1} />
                  </IconButton>
                  :
                  <IconButton id="RB_CSV" size='small' disabled={!reports.length} className={classes.buttonalign}>
                    <CSVLink
                      data={exportCSV}
                      headers={headers}
                      filename={`${params["reportName"]}.csv`}
                      target="_blank" >
                      <img src={download} alt="Icon for Download" width="28px" height="28px" className={!exportCSV.length ? classes.downloadIconStyle : classes.downloadIconStyle1} />
                    </CSVLink>
                  </IconButton>
                }</>)
              }

            </LightTooltip>
            {loading && totalRecords <= count && <CircularProgress size={38} className={classes.fabProgressPrint} />}
          </div>
        </Grid>
        <Grid item xs={12} component={Paper} sx={{ mt: 1 }}>
          <TableContainer component={Paper} className={`${classes.tableBody} ${"scrollbox"} ${"on-scrollbar"}`}>
            <Table id="ReportsDetailList" aria-label="customized table" size="small" stickyHeader>
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {reports.map(row => {
                  return (
                    <ExpandableRow onClick={handleRowClick} selected={selected === row?.agencyID} key={row?.agencyID} row={row} selectedDBTNumber={selected} />
                  );
                })}
              </TableBody>
            </Table>
            {mapped !== true && ProgressBar === false ?
              < Typography variant="h6" gutterBottom className={classes.norecord}>
                No records to display..
              </Typography>
              : null}
          </TableContainer>
          <Grid container spacing={0}>
            <Box m={0} width="60%">
              <div className="footer">
                <Footer />
              </div>
            </Box>
            <Box m={0} width="40%">
              <div className="pagination">
                <TablePagination
                  id="Report_TablePagination"
                  rowsPerPageOptions={[15]}
                  component="div"
                  count={totalRecords}
                  rowsPerPage={15}
                  page={pageNumber}
                  onChangePage={handlePageChange}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Dialog fullScreen open={openPrint} onClose={handleClose} TransitionComponent={Transition}>
        <Backdrop className={classes.backdrop} open={downloadLoading}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <AppBar className={classes.appBar} >
          <Toolbar className={classes.appBar}>
            <Box display="flex" width="100%">
              <Box width="45%" >
                <img alt="ARM_Solutions" className={classes.menuButton} src="https://www.armsolutions.com/website/wp-content/themes/arm-solutions/style/images/header-logo.png" />
              </Box>
              <Box width="55%">
                <Typography variant="h6" className={classes.printTitle} >
                  {params["reportName"].toUpperCase()}
                </Typography>
              </Box>
              <Box width="4%" display="flex">
                <style type="text/css" media="print">
                  {"\ @page { size: landscape; }\ "}
                </style>
                <LightTooltip title="Download PDF">
                  <IconButton color="primary" id="RB_PrintIcon" aria-label="download" onClick={() => { setDownloadLoading(true); downloadPDF(); }}>
                    <img src={printImg} alt="Icon for Download" width="22px" height="22px" />
                  </IconButton>
                </LightTooltip>
                {downloadLoading && <CircularProgress size={35} className={classes.fabProgressPrint1} />}
              </Box>
              <Box width="3%" display="flex" justifyContent="flex-end">
                <LightTooltip title="Close">
                  <IconButton color="primary" id="RB_PrintIcon" aria-label="print" component="label" onClick={handleClose}>
                    <CloseIcon fontSize="small" className={classes.CloseIconColor} />
                  </IconButton>
                </LightTooltip>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ padding: '15px' }}>
          <Paper className={`${classes.tablebodyPrint} scrollbox on-scrollbar`}>
            <VirtualizedTable rows={exportCSV} />
          </Paper>
        </DialogContent>
      </Dialog>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        PaperProps={{ style: { borderRadius: 15 } }}
        maxWidth={'sm'}
        onClose={handleDownloadClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleDownloadClose}><b>DOWNLOAD REPORT</b></DialogTitleHeader>
        <DialogContent>
          <Box m={0} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} p={0} pl={3} pr={3}>
            <img src={cloud} alt='icon' width={"100px"} />
            <Typography variant='h6' fontSize={"18px"} textAlign={'center'} color={'black'} mt={1}>
              <b>Your request is being processed and  you will be notified at {state.userAccessContext?.userName} shortly.</b>
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions style={{ borderTop: '1px solid lightgray' }}>
          <Button startIcon={<ReplyIcon />} variant='contained' size='small' color='success' className={classes.CloseButton} onClick={handleReportRedirect} style={{ marginRight: "10px" }}>View Request</Button>
          <Button startIcon={<CancelIcon />} variant='contained' size='small' color='error' className={classes.CloseButton} onClick={handleDownloadClose} style={{ marginRight: "20px" }}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        PaperProps={{ style: { borderRadius: 15 } }}
        maxWidth={'sm'}
        onClose={handleDialogDownloadClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleDialogDownloadClose}><b>DOWNLOAD REPORT</b></DialogTitleHeader>
        <DialogContent>
          <Box m={0} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} p={0} pl={3} pr={3}>
            <img src={file} alt='icon' width={"100px"} />
            <Typography variant='h6' fontSize={"18px"} textAlign={'center'} color={'black'} mt={1}>
              <b>The selected date range exceeds the allowed download size of 1GB, Select a lesser date range.</b>
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </div >
  )
}