import { useMsal } from '@azure/msal-react';
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, IconButton, InputAdornment, makeStyles, TablePagination, TableSortLabel, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Paper, styled, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import _ from 'lodash';
import * as React from 'react';
import { CSVLink } from "react-csv";
import PuffLoader from "react-spinners/PuffLoader";
import '../App.css';
// Icons
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from '@mui/icons-material/Close';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import download from "../images/files/download01.svg";
// Components
import Footer from '../components/Footer/Footer';
import LightTooltip from "../components/GlobalStyles/LightTooltip";
import RollExpandableRow from '../components/Roll/RollExpandableRow';
import VideoPlayer from '../components/YouTubePlayer/VideoPlayer';
// Models
import { useEffect, useRef, useState } from 'react';
import { DialogTitleHeader } from '../components/GlobalStyles/DialogStyle';
import * as constant from '../constants/Constant';
import { IDebtor } from '../models/Roll/IDebtor';
import { IRollAll } from "../models/Roll/IRollAll";
import { IRollClients } from '../models/Roll/IRollClients';
import { useGlobalState } from '../store/GlobalStore';
import { usePost } from '../utils/apiHelper';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    div: {
        flexGrow: 1,
        padding: '8px',
        overflow: 'hidden',
    },
    grid: {
        display: "flex",
        justifyContent: "center",
        justifyItems: "center"
    },
    progressBar: {
        height: '6px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    gridStyle: {
        marginTop: '-0.7cm',
    },
    searchBar: {
        marginTop: '2px',
        borderRadius: "30px",
        border: '2px solid #215176',
        height: '38px',
        textIndent: '10px',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    },
    tablebody: {
        maxHeight: 495,
        minHeight: 495,
        marginTop: '-5px',
        overflowX: 'hidden',
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 215px)',
            minHeight: 'calc( 100vh - 215px)',
        },
        '& .MuiTableContainer-root': {
            overflowX: 'hidden'
        }
    },
    label: {
        '&$focused': {
            color: 'white',
            border: '0px'
        },
    },
    outlinedInput: {
        fontSize: 15,
        color: "black",
        marginTop: '-3px',
        '&$focused $notchedOutline': {
            color: 'white',
            border: '0px'
        },
    },
    outlinedInput1: {
        fontSize: 15,
        color: "black",
        marginTop: '2px',
        paddingLeft: '10px',
        '&$focused $notchedOutline': {
            color: 'white',
            border: 'none',
        },
    },
    notchedOutline1: {
        color: 'white',
        border: '0px'
    },
    IconSizeStyle: {
        height: '30px',
        width: '30px'
    },
    footerStyle: {
        marginTop: '8px'
    },
    TableHeaderStyle: {
        background: "#007FFF",
        color: "white",
        height: '30px',
        padding: '10px 5px 5px 10px',
        fontSize: 11
    },
    textStyle: {
        marginTop: '5px',
        marginLeft: '5px',
        float: 'left'
    },
    leftbutton: {
        borderRadius: 20,
        background: "#FF0000",
        fontSize: 12,
        color: "white",
        marginTop: '7px',
        textAlign: 'center',
        marginRight: '5px',
        '&:hover': {
            background: "#FF0000",
        }
    },
    rollbutton: {
        borderRadius: 20,
        fontSize: 12,
        color: "white",
        marginTop: '7px',
        marginRight: '10px',
        float: 'right'
    },
    caption: {
        padding: 8,
        fontSize: 12
    },
    toolbar: {
        "& > p:nth-of-type(2)": {
            fontSize: 12,
            fontWeight: 600
        }
    },
    titleHeader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white',
        fontSize: 18
    },
    footer: {
        marginTop: '15px',
        float: 'right',
        marginRight: '70px'
    },
    norecord: {
        color: "red",
        marginTop: "10px",
        textAlign: "center"
    },
    title: {
        textAlign: 'left'
    },
    fabProgressPrint: {
        color: 'green',
        position: 'absolute',
        top: 6,
        left: 6,
        zIndex: 1
    },
    videoplayer: {
        marginTop: '1.05cm',
        marginLeft: '10px'
    },
    downloadicon: {
        marginLeft: '15px',
        marginTop: '-6px'
    },
    yesButton: {
        background: "green",
        color: 'white',
        borderRadius: 20,
        bottom: '0.1cm',
        right: '15px',
        fontSize: 12,
        '&:hover': {
            background: "green",
        }
    },
    noButton: {
        background: "red",
        borderRadius: 20,
        display: 'flex',
        right: '10px',
        bottom: '0.1cm',
        fontSize: 12,
        '&:hover': {
            background: "red",
        }
    }
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        background: "#007FFF",
        color: theme.palette.common.white,
        padding: '1px 5px',
        fontSize: 9.5,
        textTransform: 'uppercase',
        fontWeight: 'bold',

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 10,
        padding: 0
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0
    }
}));

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

const headCells = [
    { id: "dbT_CLIENT", numeric: false, disablePadding: false, label: "CLIENT ID", sortable: true, minWidth: 200 },
    { id: "clT_NAME_1", numeric: false, disablePadding: false, label: "CLIENT NAME 1", sortable: true },
    { id: "clT_NAME_2", numeric: false, disablePadding: false, label: "CLIENT NAME 2", sortable: true },
    { id: "dbT_NO", numeric: false, disablePadding: false, label: "AGENCY ID", sortable: true },
    { id: "dbT_CLNT_ACNT1", numeric: false, disablePadding: false, label: "ACCOUNT ID", sortable: true },
    { id: "dbT_NAME", numeric: false, disablePadding: false, label: "NAME", sortable: true },
    { id: "deM_PHONE1", numeric: false, disablePadding: false, label: "PHONE No", sortable: true },
    { id: "dbT_REFERRAL_DATE", numeric: false, disablePadding: false, label: "ACCOUNT AGING", sortable: true },
    { id: "dbT_REFERRAL_AMT", numeric: false, disablePadding: false, label: "REFERRED AMOUNT", sortable: true },
    { id: "dbT_PRINCIPAL_DUE", numeric: false, disablePadding: false, label: "BALANCE", sortable: true },
    { id: "stS_DESC", numeric: false, disablePadding: false, label: "STATUS", sortable: true },
    { id: "dbT_STATUS_DATE", numeric: false, disablePadding: false, label: "STATUS DATE", sortable: true, alignItems: 'flex-end' },
    { id: "", numeric: false, disablePadding: false, label: "ADVANCE TO CONT", sortable: false },
    { id: "", numeric: false, disablePadding: false, label: "REMOVE", sortable: false }
];

function EnhancedTableHead(props) {
    const {
        classes,
        order,
        orderBy,
        onRequestSort,
    } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <StyledTableRow>
                {headCells.map(headCell => (
                    <StyledTableCell
                        key={headCell.id}
                        style={{ minWidth: `${headCell.minWidth}`, alignItems: `${headCell.alignItems}` }}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.TableHeaderStyle}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label || headCell.sortable}
                            {orderBy === headCell.id || headCell.sortable ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </StyledTableRow>
        </TableHead>
    );
}

export default function ComparatorSortingGrid() {
    const theme = useTheme();
    const classes = useStyles();
    const { accounts } = useMsal();
    const { state } = useGlobalState();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const prevDBTNo = useRef("");
    const [page, setPage] = React.useState(0);
    const [count, setCount] = useState<number>(0);
    const [order, setOrder] = useState<any>('desc');
    const [ExportData, setExportData] = useState<any>([]);
    const [rollList, setRollList] = useState<IDebtor[]>([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [selected, setSelected] = React.useState<string>();
    const [searchText, setSearchText] = useState<string>('');
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [orderBy, setOrderBy] = useState<string>("DBT_REFERRAL_DATE");

    const wrapperRef = useRef(null);
    const [mapcount, setMapCount] = useState(false);
    const [isRollall, setIsRollall] = useState(false);
    const [expLoading, setExpLoading] = useState(false);
    const [ProgressBar, setshowProgressBar] = useState(true);
    const [triggerSearch, setTriggerSearch] = useState(false);
    const [showSearchIcon, setShowSearchIcon] = useState(true);

    const headers = [
        {
            label: "DBT_COMPANY",
            key: "dbT_COMPANY"
        },
        {
            label: "DBT_CLIENT",
            key: "dbT_CLIENT"
        },
        {
            label: "DBT_NO",
            key: "dbT_NO"
        },
        {
            label: "DBT_CLNT_ACNT1",
            key: "dbT_CLNT_ACNT1"
        },
        {
            label: "CLIENT_NAME_1",
            key: "clT_NAME_1"
        },
        {
            label: "CLIENT_NAME_2",
            key: "clT_NAME_2"
        },
        {
            label: "DBT_NAME",
            key: "dbT_NAME"
        },
        {
            label: "DBT_REFERRAL_DATE",
            key: "dbT_REFERRAL_DATE"
        },
        {
            label: "DBT_REFERRAL_AMT",
            key: "dbT_REFERRAL_AMT"
        },
        {
            label: "DBT_PRINCIPAL_DUE",
            key: "dbT_PRINCIPAL_DUE"
        },
        {
            label: "DBT_STATUS_DATE",
            key: "dbT_STATUS_DATE"
        },
        {
            label: "STS_DESC",
            key: "stS_DESC"
        },
        {
            label: "rolled",
            key: "rolled"
        },
        {
            label: "manually_rolled",
            key: "manually_rolled"
        },
        {
            label: "removed",
            key: "removed"
        },
        {
            label: "update_date",
            key: "update_date"
        }
    ];

    const handleCancelConfirmation = () => {
        setIsRollall(false);
    }

    const handleRowClick = (accountId: string) => {
        setSelected(selected == accountId ? "" : accountId);
        prevDBTNo.current = accountId;
    }

    useEffect(() => {
        getRollListAsync();
    }, [rowsPerPage, page, orderBy, order]);

    async function getRollListAsync() {
        let GetClients: IRollClients[] = [];
        if (state?.userAccessContext?.clientCodes?.length) {
            state?.userAccessContext?.clientCodes?.map((x, index) => {
                let ClientDetail: IRollClients = { clientCodes: x };
                GetClients.push(ClientDetail);
            });
        } else {
            let requestAccount = { "email": accounts[0]?.username }
            await usePost<any>("GetUserClientCode", requestAccount).then((userAcc) => {
                GetClients = userAcc?.data;
            });
        }

        let request = {
            "userId": state?.userAccessContext?.id,
            "searchText": searchText?.trim().split(" ")[0] ?? "",
            "orderBy": orderBy,
            "to": rowsPerPage,
            "orderDirection": order === "desc" ? 1 : 0,
            "startingRecordNumber": (page * rowsPerPage) + 1
        };
        setshowProgressBar(true);
        await usePost<{ rollList: IDebtor[], qty: number, remaining: number }>("GetRollDetails", request).then((rollList) => {
            setRollList(rollList.data["rollList"]);
            setMapCount(!rollList.data.qty.toString() === undefined ? false : true);
            setTotalRecords(rollList.data.qty === undefined ? 0 : rollList.data.qty);
        }).finally(() => {
            setshowProgressBar(false);
        });
    }

    const handleRequestSort = (event, property) => {
        setshowProgressBar(true);
        const isAsc = orderBy === property && order === "asc";
        setPage(0);
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setshowProgressBar(true);
        setPage(newPage);
    };


    const initiateSearch = () => {
        setPage(0);
        setTriggerSearch(!triggerSearch);
        setshowProgressBar(true);
        getRollListAsync();
        ExportDetails();
    }
    const cancelSearch = () => {
        setshowProgressBar(true);
        setShowSearchIcon(true);
        getRollListAsyncClear();
        ClearExportDetails();
        setSearchText('');
    }

    useEffect(() => {
        GetRemainingCount();
    }, [])

    const GetRemainingCount = () => {
        (async () => {
            let GetClients: IRollClients[] = [];
            if (state?.userAccessContext?.clientCodes?.length) {
                state?.userAccessContext?.clientCodes?.map((x, index) => {
                    let ClientDetail: IRollClients = { clientCodes: x };
                    GetClients.push(ClientDetail);
                });
            } else {
                let requestAccount = { "email": accounts[0]?.username }
                await usePost<any>("GetUserClientCode", requestAccount).then((userAcc) => {
                    GetClients = userAcc?.data;
                });
            }

            let request = {
                "rollClients": _.cloneDeep(GetClients)
            }

            await usePost<any>("GetRemainingCount", request).then(r => {
                setCount(r.data?.remaining);
            });

        })()
    }

    async function getRollListAsyncClear() {
        let GetClients: IRollClients[] = [];
        if (state?.userAccessContext?.clientCodes?.length) {
            state?.userAccessContext?.clientCodes?.map((x, index) => {
                let ClientDetail: IRollClients = { clientCodes: x };
                GetClients.push(ClientDetail);
            });
        } else {
            let requestAccount = { "email": accounts[0]?.username }
            await usePost<any>("GetUserClientCode", requestAccount).then((userAcc) => {
                GetClients = userAcc?.data;
            });
        }

        let request = {
            "userId": state?.userAccessContext?.id,
            "searchText": "",
            "orderBy": orderBy,
            "to": rowsPerPage,
            "orderDirection": order === "desc" ? 1 : 0,
            "startingRecordNumber": (page * rowsPerPage) + 1
        };
        setshowProgressBar(true);
        await usePost<{ rollList: IDebtor[], qty: number }>("GetRollDetails", request).then((rollList) => {
            setRollList(rollList.data["rollList"]);
            setMapCount(!rollList.data.qty.toString() === undefined ? false : true);
            setTotalRecords(rollList.data.qty === undefined ? 0 : rollList.data.qty);
        }).finally(() => {
            setshowProgressBar(false);
        });
    }

    const ClearExportDetails = () => {
        (async () => {
            let GetClients: IRollClients[] = [];
            if (state?.userAccessContext?.clientCodes?.length) {
                state?.userAccessContext?.clientCodes?.forEach((x, index) => {
                    if (x && (x != "" || x != null)) {
                        let ClientDetail: IRollClients = { clientCodes: x };
                        GetClients.push(ClientDetail);
                    }
                });
            } else {
                let requestAccount = { "email": accounts[0]?.username }
                await usePost<any>("GetUserClientCode", requestAccount).then((userAcc) => {
                    GetClients = userAcc?.data;
                });
            }

            let request = {
                "rollClients": _.cloneDeep(GetClients),
                "orderBy": orderBy,
                "dir": order === "desc" ? "desc" : "asc",
                "to": 200000,
                "searchParameters": ""
            };

            await usePost<any>("RollExportDetails", request).then((r) => {
                setExportData(r.data);
            });
        })()
    }

    const RollAll = () => {
        (async () => {
            setIsRollall(false);
            setshowProgressBar(true);
            let request = {
                "clientCode": state.userAccessContext?.clientCodes.join(","),
                "user_Id": state.userAccessContext?.id,
            };
            await usePost<IRollAll[]>('RollAll', request).finally(() => {
                getRollListAsync();
            }).finally(() => {
                GetRemainingCount();
                setshowProgressBar(false);

            });
        })()
    }

    useEffect(() => {
        setExpLoading(true);
        (async () => {
            let GetClients: IRollClients[] = [];
            if (state?.userAccessContext?.clientCodes?.length) {
                state?.userAccessContext?.clientCodes?.forEach((x, index) => {
                    if (x && (x != "" || x != null)) {
                        let ClientDetail: IRollClients = { clientCodes: x };
                        GetClients.push(ClientDetail);
                    }
                });
            } else {
                let requestAccount = { "email": accounts[0]?.username }
                await usePost<any>("GetUserClientCode", requestAccount).then((userAcc) => {
                    GetClients = userAcc?.data;
                });
            }

            let request = {
                "rollClients": _.cloneDeep(GetClients),
                "orderBy": orderBy,
                "dir": order === "desc" ? "desc" : "asc",
                "to": 200000,
                "searchParameters": searchText?.trim() ?? ""
            };
            await usePost<any>("RollExportDetails", request).then((r) => {
                setExportData(r.data);
            });
        })().finally(() => {
            setExpLoading(false);
        })
    }, []);

    const ExportDetails = () => {
        setExpLoading(true);
        (async () => {
            let GetClients: IRollClients[] = [];
            if (state?.userAccessContext?.clientCodes?.length) {
                state?.userAccessContext?.clientCodes?.forEach((x, index) => {
                    if (x && (x != "" || x != null)) {
                        let ClientDetail: IRollClients = { clientCodes: x };
                        GetClients.push(ClientDetail);
                    }
                });
            } else {
                let requestAccount = { "email": accounts[0]?.username }
                await usePost<any>("GetUserClientCode", requestAccount).then((userAcc) => {
                    GetClients = userAcc?.data;
                });
            }

            let request = {
                "rollClients": _.cloneDeep(GetClients),
                "orderBy": orderBy,
                "dir": order === "desc" ? "desc" : "asc",
                "to": 200000,
                "searchParameters": searchText?.trim() ?? ""
            };

            await usePost<any>("RollExportDetails", request).then((r) => {
                setExportData(r.data);
            });
        })().finally(() => {
            setExpLoading(false);
        })
    }

    return (
        <React.Fragment>
            <div className={classes.div} ref={wrapperRef}>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <Grid container className={classes.grid}>
                    <Grid item xs={3} sm={2}>
                        <Typography variant="h5" gutterBottom className={`${classes.textStyle} ${"headertitle"}`}>
                            <b>ROLL LIST</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={9} sm={7}>
                        <TextField value={searchText}
                            fullWidth
                            size="small"
                            id="standard-bare"
                            variant="outlined"
                            type='text' placeholder="Search (account number, name, SSN, phone)"
                            className={classes.searchBar}
                            inputProps={{ maxLength: 255 }}
                            onChange={e => setSearchText(e.target.value)}
                            onKeyPress={event => {
                                if (event.key === 'Enter' && searchText != "") {
                                    initiateSearch(); setShowSearchIcon(false);
                                } else if (event.key === 'Enter' && searchText === "") {
                                    initiateSearch(); setShowSearchIcon(true);
                                }
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.outlinedInput,
                                    notchedOutline: classes.notchedOutline1,
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {showSearchIcon === true ?
                                            <IconButton onClick={e => { initiateSearch(); setShowSearchIcon(false); }} style={{ marginLeft: '1.5cm' }}>
                                                <SearchIcon />
                                            </IconButton>
                                            :
                                            <IconButton onClick={e => { cancelSearch(); }} style={{ marginLeft: '1.5cm' }}>
                                                <CloseIcon />
                                            </IconButton>
                                        }
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} sm={2} >
                        <Button id="Roll_RollCount" className={classes.leftbutton} size="small" variant="contained">
                            {count === 0 ? 0 : count} Left
                        </Button>
                        <Button id="Roll_RollAll_Button" startIcon={<SupervisorAccountIcon />} className={classes.rollbutton} size="small" variant="contained" color="primary"
                            onClick={(_) => { setIsRollall(true); }} disabled={!state.userAccessContext?.rollAccounts || !rollList.length || !count}>
                            Roll All
                        </Button>
                    </Grid>
                    <Grid item xs={4} sm={1}>
                        <Box alignItems="flex-start" display="flex" justifyContent="flex-start">
                            <Box display="flex">
                                <LightTooltip title="Download Roll List">
                                    <IconButton id="Roll_DownloadCSV" aria-label="Roll_DownloadCSV" disabled={!rollList.length} style={{ borderRadius: 50 }}>
                                        <CSVLink
                                            data={ExportData}
                                            headers={headers}
                                            filename={"RollList.csv"}
                                            className="btn btn-primary"
                                            target="_blank" >
                                            <img src={download} alt="Icon for Download" width="30px" height="28px" style={!ExportData.length ? { color: 'gray', fontSize: 42 } : { color: "#007FFF", fontSize: 42 }} /* fontSize="large" */ />
                                        </CSVLink>
                                        {expLoading && <CircularProgress size={45} className={classes.fabProgressPrint} />}
                                    </IconButton>
                                </LightTooltip>
                            </Box>
                            <Box display="flex" style={{ marginTop: '45px', }} >
                                <VideoPlayer video={constant?.rollVideo} title={constant?.rollVideoTitle} />
                            </Box>
                        </Box>
                    </Grid >
                </Grid >

                <Paper>
                    <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
                        <Table sx={{ minWidth: 650 }} aria-label="customized table" size="small" stickyHeader>
                            <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>{stableSort(rollList, getComparator(order, orderBy)).map(
                                (row) => {
                                    return (
                                        <RollExpandableRow onClick={handleRowClick} selected={selected === row.dbT_NO}
                                            key={row.dbT_NO} onDelete={getRollListAsync} onRollRemove={getRollListAsync}
                                            onUndoCloseRemove={getRollListAsync} onEroll={getRollListAsync} row={row}
                                            selectedDBTNumber={selected} searchWord={searchText} GetCount={GetRemainingCount} />
                                    );
                                })}
                            </TableBody>
                        </Table>
                        {!ProgressBar && !rollList.length ?
                            < Typography variant="h6" gutterBottom className={classes.norecord}>
                                No records to display..
                            </Typography>
                            : null}
                    </TableContainer>
                    <Grid container spacing={0}>
                        <Box m={0} width="65%">
                            <div className="footer">
                                <Footer />
                            </div>
                        </Box>
                        <Box m={0} width="35%">
                            <div className="pagination">
                                <TablePagination
                                    id="Roll_TablePagination"
                                    rowsPerPageOptions={[15]}
                                    component="div"
                                    count={totalRecords}
                                    rowsPerPage={15}
                                    page={page}
                                    onChangePage={handleChangePage}
                                />
                            </div>
                        </Box>
                    </Grid>
                </Paper >

                <Dialog
                    PaperProps={{ style: { borderRadius: 15 } }}
                    maxWidth={'sm'}
                    fullScreen={fullScreen}
                    open={isRollall}
                    onClose={handleCancelConfirmation}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitleHeader id="responsive-dialog-title" onClose={handleCancelConfirmation}>
                        <Typography variant="h6" gutterBottom className={classes.titleHeader}>
                            ROLL ALL
                        </Typography>
                    </DialogTitleHeader>
                    <DialogContent>
                        <Typography variant="h6" style={{ color: "black" }} gutterBottom>
                            Are you sure, you want to roll all the accounts?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button id="IM_ok_btn" size="small" variant="contained" onClick={() => { RollAll(); }} className={classes.yesButton} color="primary" autoFocus>
                            Yes
                        </Button>
                        <Button id="IM_Cancel_Btn" size="small" variant="contained" autoFocus className={classes.noButton} onClick={handleCancelConfirmation} color="primary">
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        </React.Fragment >
    )
}